import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from 'components/Navbar';
import { MobileNavbarWrapper } from 'components/MobileNavbar';
import PersonalAreaBox from 'components/PersonalAreaBox';
import { Breadcrumb } from 'core/Breadcrumb';
import DashboardWidgets from '../../components/DashboardWidgets/DashboardWidgets';
import { ContentBlock } from 'tt-ui-lib/core';
import Cookies from 'js-cookie';
import {
  SHOW_ACTIVE_USER_COMPANIES,
  GET_SHORT_COMPANY_BY_ID,
  GET_USER_COMPANIES_SHORT_LIST,
  LOGOUT_USER,
  GET_CURRENT_USER,
} from 'api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import PageContainer from 'components/layout/PageContainer';
import { createTheme } from '@mui/material';
import { Feed } from '../FeedPage/FeedPage';
import { Pricing } from '../Pricing';
import { BuyCredits } from '../BuyCredits';
import { Messenger } from '../MessengerPage/MessengerPage';
import { Community } from '../CommunityPage/CommunityPage';
import { Group } from '../GroupPage/GroupPage';
import { Events } from '../Events';
import { EsgFeedPage } from '../EsgFeed/EsgFeedPage';
import Financials from '../FinancialsPage';
import CertificatePage from '../CertificatePage/index';
import { Content } from '../Content';
import { Concierge } from '../Concierge';
import { MarketPlace } from '../MarketPlace';
import { Notification } from '../NotificationPage/NotificationPage';
import { ProfilePageView } from '../ProfilePage/ProfilePage';
import { Invitation } from '../InvitationPage/InvitationPage';
import styles from './Dashboard.module.scss';
import PostPage from '../PostPage/PostPage';
import {
  closeAppLoader,
  contentEditorFocusHandler,
  getAvatarPathBySize,
  logoutUserHandler,
  openAppLoader,
} from '../../utils/appUtils';
import SupportChat from '../../modules/tt-concierge';
import { useSelectedContext } from 'context/contextSelector';
import { AdminNavbar, AppAvatar } from '../../modules/tt-navmenu';
import AuthWrapper from '../../components/AuthWarpper';
import ComingSoon from '../../components/ComingSoon';
import ReceiptPage from '../ReceiptPage';
import InvoicePage from '../InvoicePage';
import { DigitalAssetsProvider } from '../../modules/tt-digital-assets-provider';
import userContext from '../../context/User/userContext';
import RegisterPageView from '../RegisterPage';
import { FourOhFour } from '../FourOhFour';
import ScoreAndReportingRouter from 'views/ScoreAndReporting/Routers/ScoreAndReportingRouter';
import { SR_URL, URL_CALCS_NEW, URL_CALCS_SCORE } from 'constants/calculatorsPage';
import { TokenizeAndTrace } from 'views/TokinizeAndTrace';
import { Decarbonizator } from 'views/Decarbonizator';
import { Notarizator } from 'views/Notarizator';
import { Compliance } from 'views/Compliance';
import { SavedFolders } from 'views/SavedFolders';
import { Archived } from 'views/Archived';

const isProduction = process.env.REACT_APP_ENV === 'production';
const sideMenuExcludedPage = ['pricing'];
const laSalleGreenTheme = {
  headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
  sendButtonColor: '#143471',
  messageBackgroundColor: '#E3EDFF',
  supportButton: '#143471',
};
const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const endpointDA = process.env.REACT_APP_DIGITAL_ASSETS;

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const DashboardRoutes = () => {
  const router = useNavigate();
  const pageName = window.location.pathname.split('/').pop();

  const [showSupportChat, setShowSupportChat] = useState(false);
  const [idUser, setIdUser] = useState(Cookies.get('userId'));
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [ceo, setCeo] = useState();
  const [kyc, setKyc] = useState();
  const [roleName, setRoleName] = useState();
  const [invited, setInvited] = useState();
  const [visibleArea, setVisibleArea] = useState('mainContent');
  const [showNavbar, setShowNavbar] = useState(false);
  const [isCompaniesFetch, setIsCompaniesFetch] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const [scrollUpMobile, setScrollUpMobile] = useState(false);
  const [scrollUpTablet, setScrollUpTablet] = useState(false);
  const [company, setCompany] = useState(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const [isCompanyFetch, setIsCompanyFetch] = useState(false);
  const [isValidPath, setIsValidPath] = useState(false);
  const location = useLocation();

  const endpointMarket = process.env.REACT_APP_MARKET || '';
  const endpointDigitalAssets = process.env.REACT_APP_DIGITAL_ASSETS || '';
  const userId = Cookies.get('userId');
  const companyID = Cookies.get('companyId');

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);
  const [getCompany] = useLazyQuery(GET_USER_COMPANIES_SHORT_LIST, {
    variables: { user_id: userId },
  });
  const [getSelectedCompany] = useLazyQuery(GET_SHORT_COMPANY_BY_ID);
  const [getActiveCompanies] = useLazyQuery(SHOW_ACTIVE_USER_COMPANIES);
  const { disconnectSocket, connectSocket } = useSelectedContext((context) => context.messenger);
  const { user, selectedCompany, changeUserData, setSelectedCompany, removeSelectedCompany } =
    useSelectedContext((context) => context.user);

  const divRef = useRef(null);

  const scrollToTop = () => {
    divRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [logoutUser] = useMutation(LOGOUT_USER);

  const DASHBOARD_ROUTES_INFO = [
    {
      path: '/',
      element: <Navigate to="/dashboard/feed" />,
    },
    {
      path: '/feed',
      element: <Feed scrollDown={scrollDown} scrollToTop={scrollToTop} />,
    },
    {
      path: '/feed/post/:id',
      element: <PostPage />,
    },
    {
      path: '/content/post/:id',
      element: <PostPage isContent />,
    },
    {
      path: '/buy_credits/:draftId',
      element: <BuyCredits />,
    },
    {
      path: '/pricing',
      element: <Pricing />,
    },
    {
      path: '/messaging',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Messenger />
        </AuthWrapper>
      ),
    },
    {
      path: '/community',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Community />
        </AuthWrapper>
      ),
    },
    {
      path: '/invitation/:id',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Invitation />
        </AuthWrapper>
      ),
    },
    {
      path: '/community/group/:id',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Group />
        </AuthWrapper>
      ),
    },
    {
      path: '/digital_assets',
      element: <Navigate to={`${endpointDA}/dashboard/digital_assets`} />,
    },
    {
      path: '/score_and_reporting/*',
      element: <ScoreAndReportingRouter />,
    },
    {
      path: '/score_and_reporting',
      element: <ScoreAndReportingRouter />,
    },
    {
      path: '/events',
      element: <Events />,
    },
    {
      path: '/esg-feed',
      element: <EsgFeedPage />,
    },
    {
      path: '/financials',
      name: 'Financials',
      element: isProduction ? (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Financials />
        </AuthWrapper>
      ),
    },
    {
      path: '/financials/receipt/:id',
      name: 'Receipt',
      element: isProduction ? (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <ReceiptPage />
        </AuthWrapper>
      ),
    },
    {
      path: '/financials/invoice/:id',
      name: 'Invoice',
      element: isProduction ? (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <InvoicePage />
        </AuthWrapper>
      ),
    },
    {
      path: '/decarbonizator/certificate/:id',
      name: 'Certificate',
      element: isProduction ? (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <CertificatePage />
        </AuthWrapper>
      ),
    },
    {
      path: '/decarbonizator/certificate/:certId/register/:id',
      name: 'New register',
      element: isProduction ? (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <RegisterPageView />
        </AuthWrapper>
      ),
    },
    {
      path: '/decarbonizator',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Decarbonizator />
        </AuthWrapper>
      ),
    },
    {
      path: '/content',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Content />
        </AuthWrapper>
      ),
    },
    {
      path: '/tokenize',
      element: <TokenizeAndTrace />,
    },
    {
      path: '/notarizator',
      element: <Notarizator />,
    },
    {
      path: '/compliance',
      element: <Compliance />,
    },
    {
      path: '/saved-folders',
      element: <SavedFolders />,
    },
    {
      path: '/referral',
      element: (
        <ComingSoon text="Leave us your email, and you'll be the first to know when we launch." />
      ),
    },
    {
      path: '/change-role',
      element: (
        <ComingSoon text="Leave us your email, and you'll be the first to know when we launch." />
      ),
    },
    {
      path: '/archived',
      element: <Archived />,
    },
    {
      path: '/concierge',
      element: <Concierge />,
    },
    {
      path: '/marketPlace',
      element: <MarketPlace />,
    },
    {
      path: '/notification',
      element: (
        <AuthWrapper reloadAfterLogin>
          <Notification />
        </AuthWrapper>
      ),
    },
    {
      path: '/profile/:id',
      element: (
        <AuthWrapper reloadAfterLogin>
          <ProfilePageView />
        </AuthWrapper>
      ),
    },
    {
      path: '/settings',
      name: 'Settings and security',
      element: (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ),
    },
    {
      path: '/help',
      name: 'Help',
      element: (
        <ComingSoon text="All your financial documents are accurately collected and safely stored in this section. You can easily download and hold your bills and invoices for goods, services, and subscriptions. Your TT Vouchers are also collected here." />
      ),
    },
    {
      path: '*',
      name: 'Not Found',
      element: <FourOhFour />,
    },
  ];

  const onChangeCompany = (companyInfo) => {
    disconnectSocket();
    setSelectedCompany(companyInfo);
    connectSocket(companyInfo.id);
    // window.location.reload();
  };

  const onChangeToPersonal = () => {
    disconnectSocket();
    removeSelectedCompany();
    connectSocket(userId);
    // window.location.reload();
  };

  const getUserValue = async () => {
    openAppLoader();
    const userValue = await getCurrentUser();
    if (userValue?.data?.currentUser) {
      changeUserData(userValue.data.currentUser);
      setFirstName(userValue.data.currentUser.first_name);
      setLastName(userValue.data.currentUser.last_name);
      setCeo(userValue.data.currentUser.ceo_of);
      setKyc(userValue.data.currentUser.kyc);
      setRoleName(userValue.data.currentUser.role_name);
      setInvited(userValue.data.currentUser.invited_by_name);
    }
    const isAdmin = userValue?.data?.currentUser?.roles?.findIndex((role) => role.name === 'admin');
    if (isAdmin !== undefined && isAdmin !== -1) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
    setTimeout(() => closeAppLoader(), 500);
  };

  const getUserCompanies = () => {
    (async () => {
      const companyValue = await getActiveCompanies();

      if (companyValue?.data?.showActiveUserCompanies?.length) {
        setUserCompanies(companyValue?.data.showActiveUserCompanies);
      } else {
        setUserCompanies([]);
      }
    })();
  };

  useEffect(() => {
    const isValid = DASHBOARD_ROUTES_INFO.some((routeInfo) => {
      const path = `/dashboard${routeInfo.path}`;
      return location.pathname === path;
    });
    setIsValidPath(isValid);
  }, [location.pathname]);

  useEffect(() => {
    if (user.id && company === null) {
      (async () => {
        const companyValue = await getCompany();
        if (
          companyValue.data?.showAllCompaniesByUserId &&
          companyValue.data.showAllCompaniesByUserId.length > 0
        ) {
          setCompany(companyValue.data.showAllCompaniesByUserId);
        } else {
          setCompany(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (user && user.id) {
        if (!firstName) {
          await getUserValue();
        }
      } else if (idUser) {
        await getUserValue();
      } else {
        setTimeout(() => closeAppLoader(), 1000);
      }
    })();
  }, [user]);

  const getUserSelectedCompany = () => {
    (async () => {
      const companyValue = await getSelectedCompany({
        variables: { id: companyID },
      });

      if (companyValue.data?.showCompanyById) {
        onChangeCompany(companyValue.data.showCompanyById);
      } else {
        onChangeCompany(null);
      }
    })();
  };

  useEffect(() => {
    if (user.id && !isCompaniesFetch) {
      getUserCompanies();
      setIsCompaniesFetch(true);
    }
  }, [user]);

  useEffect(() => {
    if (!selectedCompany.isCompanySelected && companyID && user.id && !isCompanyFetch) {
      getUserSelectedCompany();
      setIsCompanyFetch(true);
    } else if (selectedCompany.isCompanySelected && !companyID) {
      removeSelectedCompany();
    }
  }, [companyID, selectedCompany, user]);

  const openOptionProfile = () => {
    router(`/dashboard/profile/${user?.id}`);
  };

  const redirectMarket = (url) => `${endpointMarket}${url}`;
  const redirectDigitalAssets = (url) => `${endpointDigitalAssets}${url}`;

  const logOut = () => {
    logoutUserHandler(logoutUser, disconnectSocket);
  };

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    if (window.location.pathname === '/dashboard/feed') {
      if (scrollPosition > 100) {
        if (window.innerWidth < 1200) {
          setScrollUpTablet(true);
        }
        if (window.innerWidth < 1025) {
          setScrollUpMobile(true);
          setScrollUpTablet(false);
        }
        setScrollDown(true);
      } else {
        setScrollDown(false);
        setScrollUpMobile(false);
        setScrollUpTablet(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('focusin', contentEditorFocusHandler);
    return () => document.removeEventListener('focusin', contentEditorFocusHandler);
  }, []);

  return (
    <DigitalAssetsProvider userContextObject={userContext}>
      <PageContainer>
        {isValidPath && (
          <SupportChat
            them={laSalleGreenTheme}
            showSupportChat={showSupportChat}
            setShowSupportChat={setShowSupportChat}
            companyName="TT Concierge"
            apiUrl={REACT_APP_CONCIERGE_URL} // API url
            apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
            supportMail="support@transparenterra.com"
            user={user}
            scrollUpMobile={scrollUpMobile}
            scrollUpTablet={scrollUpTablet}
          />
        )}
        <div ref={divRef} onScroll={handleScroll} className={styles.content} id="dashboardContent">
          <div className={styles.headerWrapper}>
            <AdminNavbar
              showNavbar={showNavbar}
              theme={theme}
              baseUrl=""
              baseFceUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
              mode="tt"
            />

            <Navbar
              backProps={() => setVisibleArea('mainContent')}
              avaProps={
                user && (
                  <AppAvatar
                    user={user}
                    selectedCompany={selectedCompany}
                    selectSettings={() => router('/dashboard/settings')}
                    selectSavedFolders={() => router('/dashboard/saved-folders')}
                    selectArchived={() => router('/dashboard/archived')}
                    selectReferral={() => router('/dashboard/referral')}
                    selectRole={() => router('/dashboard/change-role')}
                    selectHelp={() => router('/dashboard/help')}
                    arrowButton={1}
                    nav={2}
                    userName={
                      selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.companyName
                        : `${firstName} ${lastName}`
                    }
                    src={
                      selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.logos[0]?.path || ''
                        : user.avatarSmall
                    }
                    openOptions={1}
                    selectProfile={openOptionProfile}
                    logOut={logOut}
                    logoutUserHandler={logOut}
                    userCompanies={userCompanies}
                    selectCompany={onChangeCompany}
                    selectToPersonalProfile={onChangeToPersonal}
                    mainTTUrl=""
                    marketTTUrl={process.env.REACT_APP_MARKET || ''}
                    digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
                    mode="main"
                  />
                )
              }
              logOut={logOut}
              setOpenSupportChat={setShowSupportChat}
              marketTTUrl={process.env.REACT_APP_MARKET || ''}
              digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
              qaTTUrl={process.env.REACT_APP_QA}
            />
            <div className={styles.breadcrumb}>
              <Breadcrumb
                data={[
                  { href: '/dashboard/feed', name: 'TransparenTerra' },
                  {
                    href: window.location.pathname,
                    name: window.location.pathname.split('/').includes('profile')
                      ? 'Profile'
                      : window.location.pathname.split('/').includes('post')
                        ? 'Post'
                        : pageName,
                  },
                ]}
              />
            </div>
          </div>

          <div className={styles.pageContentWrapper}>
            <div className={styles.pageContent}>
              {!sideMenuExcludedPage.includes(pageName) && (
                <div className={styles.leftColumn} style={{ top: showNavbar ? 195 : 125 }}>
                  {company ? (
                    <PersonalAreaBox
                      avaProps={
                        <AppAvatar
                          user={user}
                          selectedCompany={selectedCompany}
                          marketTTUrl={process.env.REACT_APP_MARKET || ''}
                          userName={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.companyName
                              : `${firstName} ${lastName}`
                          }
                          src={
                            selectedCompany.isCompanySelected
                              ? getAvatarPathBySize(
                                  selectedCompany.selectedCompany.logos,
                                  'smallPath'
                                )
                              : user.avatar
                          }
                          role={selectedCompany.isCompanySelected ? '' : roleName}
                          nav={0}
                          onClickShowProfile
                          mode="main"
                        />
                      }
                      firstNameProps={{
                        children: selectedCompany.isCompanySelected
                          ? selectedCompany.selectedCompany.companyName
                          : firstName,
                      }}
                      lastNameProps={{
                        children: selectedCompany.isCompanySelected ? '' : lastName,
                      }}
                      inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                      ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                      kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                      mode="main"
                    />
                  ) : (
                    <PersonalAreaBox
                      avaProps={
                        <AppAvatar
                          user={user}
                          selectedCompany={selectedCompany}
                          marketTTUrl={process.env.REACT_APP_MARKET || ''}
                          userName={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.companyName
                              : `${firstName} ${lastName}`
                          }
                          src={
                            selectedCompany.isCompanySelected
                              ? getAvatarPathBySize(
                                  selectedCompany.selectedCompany.logos,
                                  'smallPath'
                                )
                              : user.avatarSmall
                          }
                          role={selectedCompany.isCompanySelected ? '' : roleName}
                          nav={0}
                          onClickShowProfile
                          mode="main"
                        />
                      }
                      firstNameProps={{
                        children: selectedCompany.isCompanySelected
                          ? selectedCompany.selectedCompany.companyName
                          : firstName,
                      }}
                      lastNameProps={{
                        children: selectedCompany.isCompanySelected ? '' : lastName,
                      }}
                      inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                      ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                      kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                      mode="main"
                    />
                  )}
                </div>
              )}

              <ContentBlock className={sideMenuExcludedPage.includes(pageName) && styles.maxWide}>
                <Routes>
                  {DASHBOARD_ROUTES_INFO.map((routeInfo) => (
                    <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
                  ))}
                </Routes>
              </ContentBlock>

              {!sideMenuExcludedPage.includes(pageName) && (
                <DashboardWidgets user={user} kyc={kyc} showNavbar={showNavbar} />
              )}

              <div className={styles.mobileNavigation}>
                <MobileNavbarWrapper />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </DigitalAssetsProvider>
  );
};

export default DashboardRoutes;
